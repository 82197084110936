<section class=" con-us-loc">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="tit">
                    <h2>Contact Us</h2>
                    <p>Please use the below details to contact us and we will do our best to help you.</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="con-pg-addr ">
                    <h4>Address:</h4>
                    <h5>Vellore:</h5>
                    <p>Vellore, Tamil Nadu

                    </p>
                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="con-pg-info">
                    <h4>Contact info:</h4>
                    <ul>
                        <li class="ic-pho">Support: +91 {{settings.phoneNo}}</li>
                        <li class="ic-eml">Email: {{settings.enquiryEmail}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="con-pg-soc">
                    <h4>Website &amp; Social media:</h4>
                    <ul>
                        <li class="ic-man-web"><a href="#" target="_blank">{{settings.websiteAddress}}</a></li>
                        <li class="ic-man-fb"><a href="#" target="_blank">Facebook</a></li>
                        <li class="ic-man-tw"><a href="#" target="_blank">Twitter</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>