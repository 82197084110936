import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CanonicalService } from 'src/app/canonical.service';
import { allCities } from 'src/app/places';
import { SITE_SETTINGS } from 'src/app/settings';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.css']
})
export class CityListComponent implements OnInit {

  title;
  settings = SITE_SETTINGS;
  places = allCities;
  mainCity;


  constructor(private activeRoute: ActivatedRoute, private canonicalService: CanonicalService, private titleService: Title, private metaTagService: Meta) {

  }

  ngOnInit(): void {

    this.canonicalService.setCanonicalURL();
    this.mainCity = this.activeRoute.snapshot.paramMap.get('cityName').split('-')[0];
    console.log(this.mainCity);
    this.title = `Book ${this.mainCity} Drop Taxi - Affordable Taxi Service with ${this.settings.siteName} `;
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: `Get a reliable ${this.mainCity} Drop Taxi with ${this.settings.siteName}. We provide drop taxi service in ${this.mainCity} at low cost ` }
    );
  }

}
