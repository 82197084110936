<mat-horizontal-stepper linear #stepper>
    <mat-step>
        <div id="form_container" class="wizard" novalidate="novalidate">
            <form [formGroup]="firstFormGroup" id="wrapped" class="wizard-form">
                <input id="website" name="website" type="text" value="">
                <!-- Leave for security protection, read docs for details -->
                <div id="middle-wizard" class="wizard-branch wizard-wrapper">

                    <div class="step wizard-step current" style="display: block;">
                        <div class="title">
                            <a href="tel:{{settings.phoneNo}}"> <h3 class="wizard-header" style="
                             border-top: 2px solid #2d0ed8;
                             border-bottom: 2px solid #2d0ed8;
                             padding: 5px;
                             font-size: 22px;
                         ">(+91) {{settings.phoneNo}}</h3></a>
                         </div>
                        <div class="error">
                            <mat-error *ngIf="submitted && contactValidation['name'].errors">
                                <i class="fa fa-times" aria-hidden="true"></i>
                                Enter valid name
                            </mat-error>
                            <mat-error *ngIf="submitted && contactValidation['phone'].errors">
                                <i class="fa fa-times" aria-hidden="true"></i>
                                Enter valid mobile number
                            </mat-error>
                            <mat-error *ngIf="submitted && contactValidation['email'].errors">
                                <i class="fa fa-times" aria-hidden="true"></i>
                                Enter valid email address
                            </mat-error>
                            <mat-error *ngIf="submitted && contactValidation['pickupDate'].errors">
                                <i class="fa fa-times" aria-hidden="true"></i>
                                Enter valid Pickup Date
                            </mat-error>
                            <mat-error *ngIf="submitted && contactValidation['pickupTime'].errors">
                                <i class="fa fa-times" aria-hidden="true"></i>
                                Enter valid Pickup Time
                            </mat-error>
                            <mat-error *ngIf="submitted && !origin">
                                <i class="fa fa-times" aria-hidden="true"></i>
                                Enter valid pickup address
                            </mat-error>
                            <mat-error *ngIf="submitted && !destination">
                                <i class="fa fa-times" aria-hidden="true"></i>
                                Enter valid drop address
                            </mat-error>
                            <mat-error *ngIf="submitted && !selectedCar">
                                <i class="fa fa-times" aria-hidden="true"></i>
                                Select valid car type
                            </mat-error>
                        </div>


                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <input class="form-control required" type="text" formControlName="name" id="pick_up"
                                        placeholder="Full Name">
                                    <i class="fal fa-user"></i>
                                </div>
                            </div>
                            <div class="col-6">

                                <div class="form-group">
                                    <input class="form-control required" type="text" formControlName="phone"
                                        id="drop_off" placeholder="Mobile No">
                                    <i class="fal fa-mobile"></i>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group">
                            <input class="form-control required" type="text" id="pick_up" formControlName="email"
                                placeholder="Email (optional)">
                            <i class="fal fa-envelope" aria-hidden="true"></i>
                        </div> -->

                        <div class="form-group">
                            <input class="form-control required" type="text" id="pick_up" placeholder="Pick up address"
                                #origin>
                                <img src="assets/location.png" alt="" srcset="" style="    position: absolute;
                                right: 15px;
                                top: 5px;
                            ">
                        </div>
                        <div class="form-group">
                            <input class="form-control required" type="text" id="drop_off"
                                placeholder="Drop off address" #destination>
                                <img src="assets/location.png" alt="" srcset="" style="    position: absolute;
                                right: 15px;
                                top: 5px;
                            ">
                        </div>
                        <div class="form-group">
                            <div id="undefined" class="dd-container" style="width: 100%;">
                                <div (click)="showCarList()" class="dd-select"
                                    style="width: 100%; background: rgb(255, 255, 255);"><input
                                        class="dd-selected-value required" type="hidden" name="car_type" value=""><a
                                        class="dd-selected"><img class="dd-selected-image dd-image-right"
                                            src="{{defaultCarImage}}"><label class="dd-selected-text"
                                            style="line-height: 40px;">{{defaultCarText}}</label></a></div>
                                <ul *ngIf="showCarsList" class="dd-options dd-click-off-close" style="width: 100%;">
                                    <li *ngFor="let car of cars">
                                        <a (click)="selectCar(car)" class="dd-option">
                                            <input class="dd-option-value" type="hidden" value="{{car.carType}}">
                                            <img class="dd-option-image dd-image-right" src="{{car.tariffImage}}">
                                            <label class="dd-option-text">{{car.carType}}</label>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>




                        <div class="row">

                            <div class="mb-3 col-12" style="position: relative;">
                                <div class="form-group">
                                    <label class="form-label" style="color: #000; font-weight: bold;">Confrim Travel
                                        Date</label>
                                    <mat-date-range-input class="form-control" [rangePicker]="campaignOnePicker">
                                        <input [min]="minDate" matStartDate placeholder="Pickup date"
                                            formControlName="pickupDate" readonly>
                                        <input [min]="minDate" matEndDate placeholder="Return date"
                                            formControlName="returnDate" readonly>
                                    </mat-date-range-input>
                                    <mat-hint>Pickup Date – Return Date</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix
                                        [for]="campaignOnePicker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                                </div>

                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label" style="color: #000; font-weight: bold;">Pickup
                                        Time</label>

                                    <select formControlName="pickupTime" id="inputState"
                                        class="default-select form-control wide">
                                        <option *ngFor="let time of times" [value]="time">
                                            {{time}}
                                        </option>
                                    </select>
                                    <i class="icon-clock"></i>
                                </div>
                            </div>
                        </div>




                    </div><!-- end step-->
                </div><!-- end middle-wizard -->

                <div id="bottom-wizard pb-3" class="clearfix">
                    <button (click)="requestAride()" class="job-box-cta">Get estimation</button>
                </div>


            </form>
        </div>
    </mat-step>



    <mat-step>
        <section *ngIf="show">
            <div class="container">
                <div class="row">
                    <div class="job-det-desc">
                        <div class="rhs">
                            <!--START-->
                            <div class="job-summ">
                                <h4>  Trip Estimation: Rs.{{bookingDetails.tripEstimation }} </h4>
                                <ul>
                                    <p class="mb-2 l-hght-18 font-weight-bold">Traveller’s Info.</p>

                                    <li><span>Total Distance :</span> {{bookingDetails.distance}} KM </li>
                                    <li><span>Total Duration :</span>{{bookingDetails.totalDuration}}</li>
                                    <li><span>Selected Car :</span> {{bookingDetails.carType}} </li>
                                    <li><span>Driver Allowance :</span> Included </li>
                                    <li><span>Toll :</span> Included </li>
                                    <li><span>No of Days :</span> {{days}} </li>
                                    <li><span style="color: #ffc107;font-size: 16px;width: 100%;">Note: Toll Gate and
                                            State Permit extra <br>Estimate Only - Final Bill May Differ</span></li>
                                </ul>
                                <span (click)="confirm()" class="cta-app">Confrim Booking</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </mat-step>

    <mat-step>
        <div class="success mt-5" *ngIf="bookingDetails">
            <div style="text-align: center;">
                <h3>Booking Successfull !</h3>
                <img src="assets/success.png" alt="" srcset="">
                <p class="text-black" style="
                color: #000;
                padding: 10px;
            ">
                    Thanks {{bookingDetails.name}} for Choosing {{settings.siteName}}, our team will contact you shortly with booking
                    details or <a href="tel:+91{{settings.phoneNo}}">contact us</a> now
                </p>
                <div style="text-align: center;">
                    <button (click)="home()" class="btn btn-2 mar-top">Home</button>
                </div>
            </div>
        </div>
    </mat-step>
</mat-horizontal-stepper>



<agm-map class="hide" [latitude]="latitude" [longitude]="longitude" [zoom]="5">
    <ng-container *ngIf="showDirection">
        <agm-direction [origin]="pickupPlace" [destination]="dropPlace" (onResponse)="onResponses($event)">
        </agm-direction>
    </ng-container>
</agm-map>