import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { OnewayComponent } from './components/oneway/oneway.component';
import { AgmCoreModule, LazyMapsAPILoaderConfigLiteral, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { map } from 'rxjs/operators';
import { RoundtripComponent } from './components/roundtrip/roundtrip.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { BookingformComponent } from './components/bookingform/bookingform.component';
import { AboutComponent } from './pages/about/about.component';
import { TariffComponent } from './pages/tariff/tariff.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { CityListComponent } from './pages/city-list/city-list.component';
import { CityDetailComponent } from './pages/city-detail/city-detail.component';

export function agmConfigFactory(http: HttpClient, config: LazyMapsAPILoaderConfigLiteral) {
  const id = window.location.pathname.replace(/\//g, "");
  return () => http.get<any>(`https://us-central1-calvincare-emailservice.cloudfunctions.net/mapkey`).pipe(
    map(response => {
      config.apiKey = response['mapkey'];
      console.log(response['mapkey']);
      return response;
    })
  ).toPromise();
}



@NgModule({
  declarations: [
    AppComponent,
    OnewayComponent,
    RoundtripComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BookingformComponent,
    AboutComponent,
    TariffComponent,
    ContactComponent,
    FaqComponent,
    CityListComponent,
    CityDetailComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AgmDirectionModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXTCjFyFupJ56K1JF2NglN669aCzQC8CA',
      libraries: ["places"]
    }),
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatStepperModule,
    HttpClientModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatIconModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: agmConfigFactory,
      deps: [HttpClient, LAZY_MAPS_API_CONFIG],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
