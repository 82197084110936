<!-- START -->
<section>
    <div class="jobs-cate">
        <div class="container">
            <div class="row">
                <div class="sub-tit">
                    <h2>Popular Droptaxi Cities</h2>
                    <p>Drop Taxi service</p>
                </div>
                <div class="job-cate-main">
                    <ul>
                        <li *ngFor="let city of topCities">
                            <div>
                                <h4>
                                    <a routerLink="city/{{city}}-drop-taxi" >{{city}}</a>
                                </h4>

                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END -->

<!-- START -->
<section>
    <div class="str hom2-cus hom4-fea">
        <div class="container">
            <div class="row">
                <div class="home-tit">
                    <h2><span>Top Drop Taxi Routes</span></h2>
                    <p>{{settings.siteName}} is one of the leading cab service in Chennai, provides its services between
                        various locations across Tamil Nadu at cheapest price.</p>
                </div>
                <div class="place job-tre">
                    <ul>
                        <li *ngFor="let place of pupular">
                            <div class="inn">
                                <div class="jbtre-img">
                                    <div class="jbtre-img1">
                                        <img src="{{place.image}}" alt="" loading="lazy">
                                    </div>
                                    <div class="jbtre-img2">
                                        <h4>{{place.origin}} to {{place.destination}} Drop Taxi</h4>
                                        <span>Rs. {{place?.estimation}}</span>

                                    </div>
                                </div>

                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END -->

<!-- START -->
<section>
    <div class="str count">
        <div class="container">
            <div class="row">

                <div class="how-wrks">
                    <div class="home-tit">
                        <h2><span>{{settings.siteName}}</span></h2>
                        <p>
                            We are {{settings.siteName}}, an online one way booking aggregator that offers trustworthy
                            and
                            excellent local and intercity car rental services to customers.
                        </p>
                        <br>
                        <br>

                        <p>Service We offer</p>
                    </div>
                    <div class="how-wrks-inn">
                        <ul>
                            <li>
                                <div>
                                    <span>1</span>
                                    <img src="assets/taxi.png" alt="" loading="lazy">
                                    <h4>ONEWAY TAXI
                                    </h4>
                                    <p>Pay only one side charge at rock bottom rates. If you need to be somewhere, we’ll
                                        get you there.</p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>2</span>
                                    <img src="assets/chola_icons_7.png" alt="" loading="lazy">
                                    <h4>ROUNDTRIP CABS
                                    </h4>
                                    <p>
                                        Our premium roundtrip services will pamper you with an absolutely comfortable
                                        drive from your doorstep & back.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>3</span>
                                    <img src="assets/rent.png" alt="" loading="lazy">
                                    <h4>LOCAL RENTALS
                                    </h4>
                                    <p>Our local rentals are available for 4 hours, 8 hours or 12 hours, based on your
                                        needs. </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>4</span>
                                    <img src="assets/airport.png" alt="" loading="lazy">
                                    <h4>AIRPORT TRANSFERS
                                    </h4>
                                    <p>Our airport transfer services across airports in the country offer pickups and
                                        drops with complete reliability. </p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="mob-app">
                        <div class="lhs">
                            <img src="https://img.freepik.com/free-photo/taxi-driver-female-client-interacting-formal-way_23-2149204552.jpg?" alt="" loading="lazy">
                        </div>
                       
                        <div class="rhs">
                            <div class="sub-tit">
                                <h2>Why Choose {{settings.siteName}}</h2>
                            </div>
                           
                            <ul>
                                <li>Affordability: {{settings.siteName}} is the best Drop Taxi provider in South India,
                                </li>
                                <li>Courteous Drivers: We promise to provide you reliable taxi service with our professional
                                    drivers</li>
                                <li>Timely Availability: {{settings.siteName}} always guarantees timely availability of taxi service.</li>
                                <li>Wide Range of Cars: You can choose a taxi from a wide range of cars like Indica, Innova
                                    and Sedan.</li>
                                    <li>
                                        Each and every vehicle has a GPRS tracker
                                    </li>
                                    <li>
                                        Each trip will be monitored by tracking team by GPRS tracker from before start of the trip and untill end of the trip.
                                    </li>
                                    <li>
                                        Each vehicle will involved for proper cleaning and sanitised before every trip
                                    </li>
                            </ul>
    
    
                            <a href="#"><img src="assets/images/android.png" alt="" loading="lazy"> </a>
                            <a href="#"><img src="assets/images/apple.png" alt="" loading="lazy"> </a>
                        </div>
                    </div>
                </div>


             
            </div>
        </div>
    </div>
</section>
<!-- END -->


<!-- START -->
<section>
    <div class="hom-ads">
        <div class="container">
            <div class="row">
                <div class="filt-com lhs-ads">
                    <div class="ads-box">
                        <a href="">
                            <span>Ad</span>

                            <img src="assets/images/ads/ads2.jpg" alt="" loading="lazy">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END -->

