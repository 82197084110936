export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyD0u02wvBE-KHawZVc285RdgpeqpIJlDSo",
    authDomain: "vellore-onewaytaxi.firebaseapp.com",
    databaseURL: "https://vellore-onewaytaxi-default-rtdb.firebaseio.com",
    projectId: "vellore-onewaytaxi",
    storageBucket: "vellore-onewaytaxi.appspot.com",
    messagingSenderId: "435728250083",
    appId: "1:435728250083:web:8a976df4b4b7315c02c1d6"
  }
};
