<section *ngIf="mainCity" id="clinox-blog-details" class="clinox-blog-details-content page-section-padding">
    <div class="container">
        <div class="clinox-blog-details-content">
            <div class="row">
                <div class="col-lg-12">
                    <div class="clinox-blog-details-wrap">
                        <div class="clinox-blog-details-area  headline pera-content ul-li-block">

                            <div class="inner-text headline pera-content">
                                <h1>Book {{mainCity}} Drop Taxi <br> Affordable Taxi Service with
                                    {{settings.siteName}}
                                </h1>

                                <p class="mt-3">
                                    Get a reliable {{mainCity}} Drop Taxi with {{settings.siteName}}. We
                                    provide drop taxi service in {{mainCity}} at low cost
                                </p>

                                <ul>
                                    <ng-container *ngFor="let city of places">

                                        <li  *ngIf="city.toLowerCase() !== mainCity?.toLowerCase()" >
                                            <a class="text-black-600 caption font-weight-semibold"
                                                routerLink="/city/{{mainCity  | lowercase }}/{{mainCity | lowercase}}-to-{{city | lowercase}}-drop-taxi">
                                                {{mainCity}}
                                                to {{city}} Drop Taxi</a>
                                        </li>

                                    </ng-container>

                                </ul>
<!-- 

                                <mat-list role="list">
                                    <ng-container *ngFor="let city of places">
                                        <mat-list-item
                                            routerLink="/city/{{mainCity  | lowercase }}/{{mainCity | lowercase}}-to-{{city | lowercase}}-drop-taxi"
                                            *ngIf="city.toLowerCase() !== mainCity?.toLowerCase()" role="listitem">
                                            <h6>
                                                <a class="text-black-600 caption font-weight-semibold"
                                                    routerLink="/city/{{mainCity  | lowercase }}/{{mainCity | lowercase}}-to-{{city | lowercase}}-drop-taxi">
                                                    {{mainCity}}
                                                    to {{city}} Drop Taxi</a>
                                            </h6>
                                        </mat-list-item>

                                    </ng-container>

                                </mat-list> -->
                            </div>
                        </div>




                    </div>
                </div>

            </div>
        </div>
    </div>
</section>