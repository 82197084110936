<section class=" wed-hom-footer">
    <div class="container">
        <div class="row foot-supp">
            <h2><span>Free support:</span> +91-{{settings.phoneNo}} &nbsp;&nbsp;|&nbsp;&nbsp; <span>Email:</span>
                {{settings.enquiryEmail}}</h2>
        </div>
        <div class="row wed-foot-link-pop">
            <div class="col-md-12">
                <h4>Vellore Drop Taxi</h4>
                <ul>
                    <li *ngFor="let city of footerCity">
                        <a routerLink="city/Vellore/Vellore-to-{{city}}-drop-taxi">Vellore to {{city}} Drop Taxi</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row wed-foot-link-pop">
            <div class="col-md-12">
                <h4>Chennai Drop Taxi</h4>
                <ul>
                    <li *ngFor="let city of footerCity">
                        <a routerLink="city/chennai/chennai-to-{{city}}-drop-taxi">Chennai to {{city}} Drop Taxi</a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- POPULAR TAGS -->
        <div class="row wed-foot-link-pop">
            <div class="col-md-12">
                <h4>Drop Taxi Cities</h4>
                <ul>
                    <li *ngFor="let city of cities">
                        <a routerLink="city/{{city}}-drop-taxi">{{city}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- POPULAR TAGS -->


        <div class="row wed-foot-link-1">
            <div class="col-md-4">
                <h4>Get In Touch</h4>
                <p>Address: Vellore, Tamil Nadu

                </p>
                <p>Phone: <a href="tel:{{settings.phoneNo}}">{{settings.phoneNo}}</a></p>
                <p>Email: <a href="mailto:{{settings.enquiryEmail}}">{{settings.enquiryEmail}}</a></p>
            </div>
            <div class="col-md-4 fot-app">
                <h4>DOWNLOAD OUR FREE MOBILE APPS</h4>
                <ul>
                    <li><a href=""><img src="assets/images/gstore.png" alt="" loading="lazy"></a>
                    </li>
                    <li><a href=""><img src="assets/images/astore.png" alt="" loading="lazy"></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 fot-soc">
                <h4>SOCIAL MEDIA</h4>
                <ul>
                    <li><a target="_blank" href=""><img src="assets/images/1.png" alt="" loading="lazy"></a></li>
                    <li><a target="_blank" href="#"><img src="assets/images/2.png" alt="" loading="lazy"></a></li>
                    <li><a target="_blank" href="#"><img src="assets/images/3.png" alt="" loading="lazy"></a></li>
                    <li><a target="_blank" href=""><img src="assets/images/4.png" alt="" loading="lazy"></a></li>
                    <li><a target="_blank" href=""><img src="assets/images/5.png" alt="" loading="lazy"></a></li>
                </ul>
            </div>
        </div>
        <div class="row foot-count">
            <ul>
                <li><a target="_blank" href="#">Chennai</a></li>
                <li><a target="_blank" href="#">Bangalore</a></li>
                <li><a target="_blank" href="#">Coimbatore</a></li>
                <li><a target="_blank" href="#">Tirupati</a></li>
                <li><a target="_blank" href="#">Pondicherry</a></li>
                <li><a target="_blank" href="#">Kochi</a></li>
                <li><a target="_blank" href="#">Hyderabad</a></li>
            </ul>
        </div>
    </div>
</section>

<!-- START -->
<section>
    <div class="cr">
        <div class="container">
            <div class="row">
                <p>Copyright © 2024 <a href="#" target="_blank">{{settings.siteName}}</a>. Proudly
                    powered by <a href="https://www.calvincareinfotech.com" target="_blank">Calvin Care</a></p>
            </div>
        </div>
    </div>
</section>
<!-- END -->

<!-- START -->
<div class="fqui-menu">
    <ul>
    <li><a href="https://{{settings.websiteAddress}}"><img src="assets/images/icon/home.png">Home</a></li>

        <li><a href="https://wa.me{{settings.whatsapp}}"><span class="mob-sear"><img src="assets/images/icon/search1.png">Whatsapp</span></a></li>
        <li><a href="tel:{{settings.phoneNo}}" class="act"><img
                    src="assets/images/icon/shop.png">Call Us</a></li>
        <li><a href="https://{{settings.websiteAddress}}/tariff"><img
                    src="assets/images/icon/expert.png">Tariff</a></li>
        <li><a href="https://{{settings.websiteAddress}}/about"><img src="assets/jobs/images/icon/employee.png">About</a>
        </li>
       
    </ul>
</div>