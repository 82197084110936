<section class="p-0" style="padding: 0;">
    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
        <ng-container *ngIf="showDirection">
            <agm-direction [origin]="origin" [destination]="destination" (onResponse)="onResponses($event)">
            </agm-direction>
        </ng-container>
    </agm-map>
</section>


<section id="clinox-blog-details" class="clinox-blog-details-content page-section-padding">
    <div class="container">
        <div class="clinox-blog-details-content">
            <div class="row">

                <div class="col-12">
                    <h1>Book {{origin}} to {{destination}} Drop Taxi with {{settings.siteName}}</h1>
                    <p class="mt-5">
                        Book {{this.origin}} to {{this.destination}} Drop Taxi. Get a reliable {{this.origin}} to
                        {{this.destination}} drop taxi with {{this.settings.siteName}}. Our courteous drivers will make
                        your trip memorable when you choose cabs from {{this.origin}} to {{this.destination}}.
                    </p>
                </div>
                <div class="col-lg-4">
                    <div class="clinox-blog-sidebar  top-sticky-sidebar">
                        <h2>Get Estimate for {{origin}} to {{destination}}</h2>
                        <app-bookingform></app-bookingform>




                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="clinox-blog-details-wrap">
                        <div class="clinox-blog-details-area  headline pera-content ul-li-block">
                            <div class="inner-img">
                                <img src="assets/img/blog/blg-p1.jpg" alt="">
                            </div>
                            <div class="inner-text headline pera-content">

                                <h3 class="mb-5"> {{origin}} To {{destination}} One Way Rates
                                </h3>


                                <table style="width:100%" colspan="2" class="mt-5 mb-5">
                                    <tbody>
                                        <tr>
                                            <th>Vehicle Type</th>
                                            <th>Rate/KM</th>
                                            <th>Trip Fare</th>
                                            <th>Additional Charge</th>
                                        </tr>
                                        <tr *ngFor="let car of cars">
                                            <td>{{car.carType}}</td>
                                            <td>Rs.{{car.oneway}}/KM</td>
                                            <td>Rs.{{car?.tripEstimation}}</td>
                                            <td>One Way Toll</td>
                                        </tr>

                                    </tbody>
                                </table>


                                <h3 class="mb-5">Book {{origin}} To {{destination}} Taxi
                                </h3>
                                <p>
                                    Book our {{origin}} to {{destination}} cab service and rest assured you will get
                                    quality &
                                    reliable services for your Car Rental from {{origin}} to {{destination}}. With our
                                    wide
                                    range of services, you can now book your {{origin}} to {{destination}} taxi in the
                                    easiest
                                    way with few simple clicks. Avail {{origin}} to {{destination}} car hire services at
                                    {{settings.siteName}} and enjoy a great trip with family and friends. Make the best
                                    use of the
                                    services offered by {{settings.siteName}} at the best rates & experience the
                                    relaxing and trouble
                                    free journey by hiring {{origin}} to {{destination}} taxi at {{settings.siteName}}.
                                    With over a decade of
                                    experience in car rental services, {{settings.siteName}} is the best cab service
                                    provider in the
                                    industry, if you are planning to book a taxi.


                                </p>

                                <h4>Cab Booking app for {{origin}} to {{destination}} taxi - Download the
                                    {{settings.siteName}} Android app
                                </h4>
                                <p>
                                    You can book any type of car for your {{origin}} to {{destination}} taxi service
                                    depending on
                                    your requirement. You can easily book {{origin}} to {{destination}} cabs at
                                    {{settings.siteName}} 24/7
                                    without any hassle with our easy-to-use booking interface. Book our {{origin}} to
                                    {{destination}} cab service and rest assured you will get quality & reliable
                                    services for
                                    your Car Rental from {{origin}} to {{destination}}.


                                </p>
                                <p>
                                    With our wide range of services, you can now book your {{origin}} to {{destination}}
                                    taxi in
                                    the easiest way with few simple clicks. Avail {{origin}} to {{destination}} car hire
                                    services
                                    at {{settings.siteName}} and enjoy a great trip with family and friends.
                                </p>
                                <h4>Why Choose {{settings.siteName}} for your {{origin}} to {{destination}} travel ?
                                </h4>
                                <ul>
                                    <li>Affordability: {{settings.siteName}} is the best car rental provider in India,
                                        if you are
                                        looking for an affordable taxi fare from {{origin}} to {{destination}}.
                                    </li>
                                    <li>Courteous Drivers: We promise to provide you reliable taxi service with our
                                        professional drivers.
                                    </li>
                                    <li>Timely Availability: {{settings.siteName}} always guarantees timely availability
                                        of taxi
                                        service.
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>